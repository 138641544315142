import * as React from "react";
import { Link, withPrefix } from "gatsby";
import { WindowLocation } from "@reach/router";
import useScrollSpy from "../../hooks/useScrollSpy";

import styles from "./toc.module.scss";

export interface TOCProps {
  slug: string;
  items: any[];
  location: WindowLocation;
}

const getMenuIdList = menu => {
  let result = [];

  function getMenuIdList(menu) {
    menu.forEach(item => {
      item.url && result.push(item.url);
      if (Array.isArray(item.items) && item.items.length > 0) {
        getMenuIdList(item.items);
      }
    });
  }

  getMenuIdList(menu);

  return result;
};

function TableOfContents(
  { slug, items, location }: TOCProps,
  ref: React.MutableRefObject<HTMLElement>
) {
  const innerRef = React.useRef<HTMLDivElement>();
  React.useImperativeHandle(ref, () => innerRef.current);

  const [pathname, setPathname] = React.useState("");
  if (items === null) {
    return null;
  }

  // Ignore h1 and only get h2 & h3
  // To make sure it work properly, a page should not contain H1 tag which should be defined in frontmatter title
  const menu = React.useMemo(
    () =>
      (items || []).map(item => {
        const cloned = { ...item };
        if (cloned.items) {
          cloned.items = cloned.items.map(({ items, ...child }) => child);
        }
        return cloned;
      }),
    [items]
  );

  const { activeSection, setActiveSectionFromOutsite } = useScrollSpy({
    items: getMenuIdList(menu),
    activeSectionDefault: location.hash,
    offsetPx: -80
  });

  React.useEffect(() => {
    const { href, origin } = location;
    setPathname(href.replace(origin, ""));
  }, [location]);

  function itemClick(item) {
    const { slug, url } = item;
    setPathname(withPrefix(slug + url));
    setActiveSectionFromOutsite(url);
  }

  return menu.length > 0 ? (
    <div ref={innerRef} className={styles.container}>
      <div className={styles.wrapper}>
        <h5 className={styles.heading}>Table of Contents</h5>
        <ul className={styles.list}>
          {menu.map(item => (
            <Item
              itemClick={itemClick}
              key={item.url}
              pathname={pathname}
              activeLink={activeSection}
              slug={slug}
              title={item.title}
              url={item.url}
              items={item.items}
            />
          ))}
        </ul>
      </div>
    </div>
  ) : null;
}

export default React.forwardRef(TableOfContents);

function Item(props) {
  const { activeLink, slug, url, title, items, pathname, itemClick } = props;
  const active = activeLink === url;
  return (
    <li>
      <Link
        to={slug + url}
        onClick={e => {
          e.preventDefault();
          itemClick(props);
        }}
        className={styles.linkInactive + ` ${active ? styles.linkActive : ""}`}
      >
        {title}
      </Link>
      {items !== undefined && items.length > 0 && (
        <ul>
          {items.map(item => {
            return (
              <Item
                itemClick={itemClick}
                activeLink={activeLink}
                key={item.url}
                pathname={pathname}
                slug={slug}
                title={item.title}
                url={item.url}
                items={item.items}
              />
            );
          })}
        </ul>
      )}
    </li>
  );
}
