import { useState, useEffect, useCallback } from "react";
import { useRef } from "react";

const getElementSection = menu => {
  let result = [];

  function mappingMenu(menu) {
    menu.forEach(item => {
      item.url &&
        result.push(document.getElementById(item.url.replace("#", "")));
      if (Array.isArray(item.items) && item.items.length > 0) {
        mappingMenu(item.items);
      }
    });
  }

  mappingMenu(menu);

  return result;
};

function scrollToView(reference: string) {
  const node = document.querySelector(reference);
  if (!node) {
    return;
  }
  node.scrollIntoView(true);
}

export default function useScrollSpy({
  activeSectionDefault = "",
  offsetPx = 0,
  items = []
}) {
  const [activeSection, setActiveSection] = useState<string>("");
  const [blocked, setBlocked] = useState(false);

  useEffect(() => {
    const sectionElementRefs = items.map(item =>
      document.getElementById(item.replace("#", ""))
    );

    const handleScroll = () => {
      if (blocked) {
        return;
      }
      let currentSectionId = "";
      for (let i = 0; i < sectionElementRefs.length; i++) {
        const section = sectionElementRefs[i];
        // Needs to be a valid DOM Element
        if (!section || !(section instanceof Element)) continue;
        // GetBoundingClientRect returns values relative to viewport
        if (section.getBoundingClientRect().top + offsetPx < 0) {
          currentSectionId = `#${section.id}`;
          continue;
        }
        // No need to continue loop, if last element has been detected
        break;
      }
      setActiveSection(currentSectionId);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [offsetPx, blocked]);

  useEffect(() => {
    // Trigger scroll on first load
    if (!activeSectionDefault) {
      return;
    }
    setActiveSection(activeSectionDefault);
    setBlocked(true);
    scrollToView(activeSectionDefault);
    const timeout = setTimeout(() => {
      setBlocked(false);
    }, 500);

    return () => {
      setBlocked(false);
      clearTimeout(timeout);
    };
  }, [activeSectionDefault]);

  function setActiveSectionFromOutsite(section: string) {
    setBlocked(true);
    setActiveSection(section);
    scrollToView(section);
    setTimeout(() => {
      setBlocked(false);
    }, 500);
  }

  return { activeSection, setActiveSectionFromOutsite };
}
