import * as React from "react";
import { Location } from "@reach/router";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";

import Layout, { LayoutProps } from "../default";
import SEO from "../../components/seo";
import { TOC } from "../../components/tableOfContent";

import styles from "./style.module.scss";
import { NextPrevNavigation } from "../../components/nextPrevNavigation";
import Breadcrum from "../../components/search/components/Breadcrum";

export const query = graphql`
  query PageQuery($id: String) {
    mdx(id: { eq: $id }) {
      id

      fields {
        slug
      }

      exports {
        meta {
          title
          description
        }
        nav {
          title
          parent
        }
      }

      body

      tableOfContents(maxDepth: 3)
    }
  }
`;

export interface DocumentationLayoutProps extends LayoutProps {
  data: any;
  pageContext: {
    publishedDate: string;
    modifiedDate: string;
  };
}

export default function DocumentationLayout({
  data: { mdx },
  pageContext: { publishedDate, modifiedDate }
}: DocumentationLayoutProps) {
  return (
    <Layout>
      {callbackRef => {
        const tocRef = React.useRef<HTMLElement>();
        React.useEffect(() => {
          if (tocRef) {
            callbackRef(tocRef);
          }
        }, []);
        return (
          <>
            <SEO
              title={mdx.exports.meta.title || undefined}
              description={mdx.exports.meta.description || undefined}
              publishedDate={publishedDate}
              modifiedDate={modifiedDate}
            />
            <div>
              <Breadcrum>{mdx.exports.nav && mdx.exports.nav.parent}</Breadcrum>
              <div className="markdown-content">
                {mdx.exports.nav && mdx.exports.nav.title && (
                  <h1>{mdx.exports.nav.title}</h1>
                )}
                {
                  // @ts-ignore
                  <MDXRenderer>{mdx.body}</MDXRenderer>
                }
              </div>
              <NextPrevNavigation />
            </div>
            <aside className={styles.toc}>
              <Location>
                {({ location }) => (
                  <TOC
                    ref={tocRef}
                    slug={mdx.fields.slug}
                    items={mdx.tableOfContents.items}
                    location={location}
                  />
                )}
              </Location>
            </aside>
          </>
        );
      }}
    </Layout>
  );
}
