import * as React from "react";
import { Link } from "gatsby";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { Location } from "@reach/router";
import items from "../../../docs/site-navigation";

import styles from "./styles.module.scss";

interface NavigationInterface {
  path: string;
  title: string;
  external?: boolean;
}

interface NextPrevNavigationProps {
  next?: NavigationInterface;
  prev?: NavigationInterface;
}

interface ExtendedNavigationInterface extends NavigationInterface {
  isNext?: boolean;
}

const Navigation = ({
  path,
  title,
  external = false,
  isNext = false
}: ExtendedNavigationInterface) => (
  <div className={styles.path + (isNext ? ` ${styles.next}` : "")}>
    <h6>
      {isNext ? (
        <>
          Next <MdChevronRight />
        </>
      ) : (
        <>
          <MdChevronLeft />
          Prev
        </>
      )}
    </h6>
    {external ? (
      <a
        href={path}
        className="hyperlink"
        target="_blank"
        rel="noopener noreferrer"
      >
        {title}
      </a>
    ) : (
      <Link className="hyperlink" to={path}>
        {title}
      </Link>
    )}
  </div>
);

const NextPrevNavigationInner = ({ next, prev }: NextPrevNavigationProps) => {
  if (!next && !prev) {
    return null;
  }
  return (
    <div className={styles.container}>
      <div>{prev && <Navigation {...prev} />}</div>
      <div>{next && <Navigation {...next} isNext />}</div>
    </div>
  );
};

const flatten = items => {
  return items.reduce((a, c) => {
    if (Array.isArray(c.items)) {
      return [...a, ...flatten(c.items)];
    } else {
      return [...a, c];
    }
  }, []);
};

export function NextPrevNavigation() {
  return (
    <Location>
      {locationProp => {
        const { pathname } = locationProp.location;
        const flattenItems = flatten(items);
        const index = flattenItems.findIndex(({ path }) => path === pathname);
        if (index === -1) {
          return null;
        }
        const next = flattenItems[index + 1];
        const prev = flattenItems[index - 1];
        return <NextPrevNavigationInner next={next} prev={prev} />;
      }}
    </Location>
  );
}
